import React, {useState, useEffect} from "react";
import Layout from "../Components/Layout/index";
import Portada from "../utils/Portada";
import Mobile from "../Assets/portada_formacion.webp";
import Desktop from "../Assets/portada_formacion_desktop.webp";
import TrainingBoxes from "../Components/TrainingBoxes";

const Formacion = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted ? (
    <Layout>
        <Portada imageDesktop={Desktop} imageMobile={Mobile} title="Formación IMON"/>
        <TrainingBoxes />
    </Layout>
  ): null
}
export default Formacion 