import React from "react";
import BoxContainer from "../utils/BoxContainer";
import styled from "styled-components";
import Check from "../Assets/icon_check.svg";

const StyledTraining = styled.div`
  background-color: var(--white);
  padding: 0 16px;
  margin-bottom: 40px;
  h1 {
    font-family: "lato_regular";
    font-size: 20px;
    color: var(--gray-strong);
    width: 100%;
    text-align: center;
    margin: 60px auto;
    font-weight: 500;
  }
  .container_boxes {
    display: flex;
    flex-direction: column;
  }
  .container_box {
    border-radius: 16px;
    padding: 40px 20px;
    h2 {
      font-size: 24px;
      margin-bottom: 32px;
      text-align: center;
      font-family: "lato_bold";
      font-weight: 520;
    }
    p {
      margin-bottom: 44px;
      font-family: "lato_regular";
    }
    h3 {
      margin-bottom: 16px;
      font-family: "lato_italic";
      font-style: italic;
      font-size: 20px;
    }
    a {
      color: white;
      cursor: pointer;
    }
    .check {
      margin-bottom: 44px;
      .item_check {
        margin-bottom: 5px;
        display: flex;
        align-items: flex-start;
        img {
          width: 12px;
          margin-top: 3px;
        }
        span {
          font-family: "lato_regular";
          font-size: 16px;
          margin-left: 5px;
          padding-bottom: 10px;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    padding: 0 100px;
    h1 {
      max-width: 680px;
      font-size: 30px;
    }
    .container_boxes {
      display: flex;
      flex-direction: row;
      width: 100%;
      .container_box_top {
        width: 50%;
      }
      .subcontainer_box {
        width: 50%;
      }
    }
    .container_box{
        margin: 10px;
        padding: 40px;
    }
  }
`;
export default function TrainingBoxes() {
  return (
    <StyledTraining>
      <h1>
        IMON es una sede reconocida por la División de Estudios de Posgrado de
        la Facultad de Medicina de la Universidad Nacional Autónoma de México
        (UNAM) para impartir los Cursos de Posgrado de Alta Especialidad en
        Medicina.
      </h1>
      <div className="container_boxes">
        <div className="container_box_top">
          <BoxContainer secondary>
            <h2>Cursos de Posgrado de Alta Especialidad en Medicina (CPAEM)</h2>
            <p>
              Los Cursos de Posgrado de Alta Especialidad en Medicina (CPAEM)
              están dirigidos a la formación de especialistas con una
              especialidad concluida. Cada uno está circunscrito a un campo de
              cierta especialidad y tienen un diseño curricular.
            </p>
            <h3>Objetivo</h3>
            <p>
              Desarrollar habilidades y profundizar en conocimientos que, por
              sus características, requieren de estudios y destrezas que van más
              allá de lo aprendido en la especialidad.
            </p>
            <h3>Descripción del programa</h3>
            <p>
              Los alumnos participan en el proceso diagnóstico-terapéutico de
              diferentes patologías otológicas como trastornos de la audición,
              equilibrio y lesiones del nervio facial. Asimismo, realizan
              actividades de investigación y publicación, incluyendo la
              realización de un proyecto de tesis para la obtención de grado.
            </p>
            <div className="check">
              <div className="item_check">
                <img src={Check} alt="icon_check" />
                <span>Tiene un carácter tutelar</span>
              </div>
              <div className="item_check">
                <img src={Check} alt="icon_check" />
                <span>Tiene una duración de 2 años</span>
              </div>
              <div className="item_check">
                <img className="elevate" src={Check} alt="icon_check" />
                <span>
                  Requiere de un tiempo exclusivo por parte del alumno
                </span>
              </div>
            </div>

            <h3>Requisitos para los aspirantes</h3>

            <div className="check">
              <div className="item_check">
                <img src={Check} alt="icon_check" />
                <span>
                  Ser otorrinolaringólogos certificados por el Consejo Mexicano
                  de la especialidad.
                </span>
              </div>
              <div className="item_check">
                <img src={Check} alt="icon_check" />
                <span>
                  El comité de admisión selecciona a un aspirante cada 2 años
                  para el programa.
                </span>
              </div>
            </div>
            <p>
              IMON es una sede reconocida por la División de Estudios de
              Posgrado de la Facultad de Medicina de la Universidad Nacional
              Autónoma de México (UNAM) para impartir los Cursos de Posgrado de
              Alta Especialidad en Medicina.
            </p>
            <p>
              Más información:
              <a href="mailto:info@imon.com.mx">info@imon.com.mx</a>
            </p>
          </BoxContainer>
        </div>
        <div className="subcontainer_box">
          <BoxContainer third>
            <h2>Método auditivo-verbal</h2>
            <p>
              Profundiza en los principios teóricos y prácticos del tratamiento
              a personas con pérdida auditiva.
            </p>
            <h3>Descripción del programa</h3>
            <div className="check">
              <div className="item_check">
                <img src={Check} alt="icon_check" />
                <span>Se apertura una vez al año</span>
              </div>
              <div className="item_check">
                <img src={Check} alt="icon_check" />
                <span>
                  Duración: dos días (viernes 9:00 a 18:00 y sábado 9:00 a
                  14:00)
                </span>
              </div>
              <div className="item_check">
                <img src={Check} alt="icon_check" />
                <span>Modalidad: presencial y en línea</span>
              </div>
              <div className="item_check">
                <img src={Check} alt="icon_check" />
                <span>
                  Requisitos: Profesionales o estudiantes en el área de salud
                  y/o educación con experiencia en la habilitación y/o
                  rehabilitación de pacientes con pérdida auditiva.
                </span>
              </div>
            </div>
            <p>
              Nota. Si quieres la certificación de puntos de la AG Bell (ajeno a
              IMON) necesitarás hacer un examen al concluir el programa.
            </p>
            <p>
              Más información:
              <a href="mailto:info@amaoir.org">info@amaoir.org</a>
            </p>
          </BoxContainer>
          <BoxContainer fourth>
            <h2>Curso a distancia en método auditivo-verbal</h2>
            <p>
              Dirigido a profesionales que trabajan en la rehabilitación de
              personas con pérdida auditiva.
            </p>
            <p>
              Enfocado a que el alumno adquiera los conocimientos fundamentales
              del MAV con el fin de ser capaces de dar una terapia congruente
              con las posibilidades del paciente, aprovechando al máximo la
              tecnología disponible y siendo capaces de orientar adecuadamente a
              la familia y a los maestros del paciente para que sean parte
              medular en su rehabilitación.
            </p>
            <h3>Descripción del programa</h3>
            <p>
              El curso consta de 3 módulos que abarcan desde la introducción al
              método, hasta formas prácticas de implementar lo aprendido y los
              temas se abarcan a través de lecturas y documentos de diferentes
              temas referentes a la rehabilitación auditiva verbal.
            </p>
            <p>
              Al final de cada tema el alumno recibe distintas asignaciones y un
              examen, los cuales son revisados por un profesional en el tema,
              quien le dará sus observaciones y recomendaciones.
            </p>

            <div className="check">
              <div className="item_check">
                <img src={Check} alt="icon_check" />
                <span>Duración: 40 horas</span>
              </div>
              <div className="item_check">
                <img src={Check} alt="icon_check" />
                <span>Modalidad: En línea</span>
              </div>
              <div className="item_check">
                <img src={Check} alt="icon_check" />
                <span>
                  Requisitos: Profesionales o estudiantes en el área de salud
                  y/o educación con experiencia en la habilitación y/o
                  rehabilitación de pacientes con pérdida auditiva.
                </span>
              </div>
            </div>
          </BoxContainer>
        </div>
      </div>
    </StyledTraining>
  );
}
